/*
This is where you write custom SASS
*/
body {
    font-size: 18px;
}
.more-width {
    max-width: 1238px;
}
.bg-wrap {
    background: #808f3a url('/prositesStyles/StyleLibrary/6314/images/body-bg.jpg') no-repeat center top fixed;
}
.bg-nav {
    background-color: #002e5d;
}
.address1 {
    background-color: #7D8685;

    p {
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        letter-spacing: 1.28px;
        text-transform: uppercase;

    }
}
.main-footer {
    background-color: #161918;

    br {
        display: none;
    }
    .foot-title {
        font-family: 'Lato', sans-serif;
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
        letter-spacing: 1.12px;
        font-weight: normal;
    }
    a {
        color: rgba(255, 255, 255, 0.5);
    }
    a:hover {
        color: rgba(255, 255, 255,1);
    }
}
.main-contact {
    background-color: #707D7D;

    .tag {
        font-family: 'Segoe UI Regular';
        font-weight: normal;
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 40px;
    }
    input {
        width: 50%;
        border-radius: 0;
    }
    textarea {
        width: 50%;
        float: right;
        position: relative;
        top: -195px;
        right: -15px;
        min-height: 180px;
        border-radius: 0;
        padding: 15px 20px !important;
    }
    .btn {
        float: right;
        width: 15%;
        position: relative;
        left: 51.6%;
        padding: 15px 30px;
    }
    .form-group {
        margin-bottom: 15px;
    }
    .mt-3 {
        margin-top: 15px !important;
    }
    input::placeholder {
        color: #7D8685;
        opacity: 1;
    }
    textarea::placeholder {
        color: #7D8685;
        opacity: 1;
    }
    .mod {
        .form-control {
            background-color: #fff;
            border: none;
            padding: 25px 20px;
        }
    }
}
.navbar ul.navbar-nav a.current {
    font-weight: bold;
}
header {
    .mod {
        a {
            color: #7D8685;
            text-transform: capitalize;
        }
        a:hover {
            color: rgb(110, 117, 116);
        }
        p {
            margin-top: -5px !important;
        }
    }
}
.main-slide {
    .slide-tag {
       font-size: 50px; 
       color: #403F3F;
       font-weight: 300;
       text-transform: uppercase;
       line-height: 53px;
       position: relative;
       right: 100px;

       span {
           color: #002e5d;
           font-weight: normal;
       }
    }
}
.testimonials {
    .owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
        display: none !important;
    }
    a {
        color: #fff;
    }
    .author {
        font-family: 'Open Sans', sans-serif;
        font-size: 21px;
        font-weight: bold;
        color: #fff;
        font-style: normal;
        letter-spacing: 0;
        display: block;
        margin-top: 50px;
    }
}
.ctas {
    p {
        font-family: 'Lato', sans-serif;
        font-size: 21px;
        font-weight: bold;
        letter-spacing: 1.68px;
        text-transform: uppercase;
    }
    a:hover .panel-image {
        background-color: #128ca1 !important;
        -webkit-transition: all .35s;
        -moz-transition: all .35s;
        transition: all .35s;
    }
}
.main-content {
    .sub-title {
        color: #403F3F;
        font-size: 24px;
        font-weight: normal;
    }
    .btm-tag {
        font-size: 18px;
        font-weight: 600;
        font-style: italic;
        color: #707070;
    }
}
.btn-primary {
    background-color: #002e5d;
    border-radius: 0;
    color: #fff;
}
.btn-primary:hover {
    background-color: #0b539a;
    color: #fff;
}
.btn-primary2 {
    background-color: #002e5d;
    border-radius: 0;
    color: #fff;
}
.btn-primary2:hover {
    background-color: #00376e;
    color: #fff;
}
.btn-primary3 {
    background-color: #16B1CD;
    border-radius: 0;
    color: #fff;
}
.btn-primary3:hover {
    background-color: rgb(18, 140, 161);
    color: #fff;
}
h1 {
    color: #002e5d;
}
.interior {
    .main-content {
        a {
            font-weight: bold;
        }
        a:hover {
            text-decoration: underline !important;
        }
        a.btn:hover {
            text-decoration: none !important;
        }
    }
    .contact-form {
        .form {
            .card-body {
                padding: 20px 50px;
                border-left: 1px solid rgba(112, 125, 125, 0.16);
            }
        }
        .form-group {
            margin-bottom: 10px;
            margin-top: 10px !important;
    
            .form-control {
                border: 1px solid #dedede;
                border-radius: 0;
                padding: 1.6rem .75rem;
            }
            label {
                display: none;
            }
        }
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: rgba(125, 134, 133, 0.68);
            opacity: 1; /* Firefox */
        }
        
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: rgba(125, 134, 133, 0.68);;
        }
        
        ::-ms-input-placeholder { /* Microsoft Edge */
            color: rgba(125, 134, 133, 0.68);;
        }
        textarea {
            padding: 1rem .75rem !important;
            min-height: 121px;
            margin-bottom: 25px;
        }
        .h3 { 
            font-family: 'Open Sans', sans-serif;
            font-size: 26px;
            font-weight: normal;
            text-transform: capitalize;
            margin-bottom: 20px;
            color: #403F3F;
        }
        .mb-3 {
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            font-weight: normal;
            color: #707070;
            line-height: 21px;
            margin-bottom: 25px !important;
        }
        .btn-primary {
            background-color: #16B1CD;
            border-radius: 0;
            color: #fff;
        }
        .btn-primary:hover {
            background-color: rgb(18, 140, 161);
            color: #fff;
        }
    }
}
.financing-page {
    overflow: hidden;
}
.testimonials {
    background-color: #002e5d;
}







@media (max-width: 1200px) {
    .associations {
        a {
            margin: 5px 40px !important;
            position: relative;
            display: inline-block;
        }
    }
    .ctas {
        p {
            font-size: 18px;
        }
    } 
    .main-contact {
        .btn {
            left: 52%;
        }
    } 
    .more-width {
        max-width: 1140px;
    }
}
@media (max-width: 992px) {
    .address1 {
        line-height: 23px;
    }
    .associations {
        a {
            margin: 20px 0 !important;
            display: block;
        }
    } 
    .main-slide {
        .panel1 {
            background-position: 40% 20% !important;
        }
        .panel2 {
            background-position: 55% 20% !important;
        }
        .panel3 {
            background-position: 30% 20% !important;
        }
    }
    .main-footer {
        .foot-title {
            font-size: 12px;
        }
    } 
    .testimonials {
        .mod1 {
            margin: 0 !important;
        }
    }
    .navbar {
        a.text-white:hover {
            color: #fff !important;
        }
    }
    header {
        .mod {
            margin-top: 30px !important;
        }
    }
    .dropdown {
        .dropdown-menu {
            border-radius: 0 !important;
        }
    } 
    .more-width {
        max-width: 960px;
    }
}
@media (max-width: 768px) {
    .address1 {
        p {
            line-height: 23px;
        }
    }
    .main-footer {
        text-align: center;
    }
    header {
        .logo {
            display: block !important;
            img {
                margin: 0 auto;
            }
        }
        .mod {
            margin-top: 10px !important;
        }
    }
    .main-slide {
        .panel1 {
            background-position: 55% 20% !important;
        }
        .panel2 {
            background-position: 55% 20% !important;
        }
        .panel3 {
            background-position: 50% 20% !important;
        }
        .slide-tag {
            font-size: 35px;
            line-height: 39px;
            right: 0;
            float: left;
        }
    }
    .main-contact {
        input {
            width: 100%;
        }
        textarea {
            width: 100%;
            float: none;
            top: 0;
            right: 0;
        }
        .btn {
            float: none;
            width: 30%;
            margin: 0 auto;
            display: block;
            left: 0;
        }
        .tag {
            text-align: center !important;
        }
    } 
    .testimonials {
        .mod1 {
            text-align: center !important;

        }
        .panel-image {
            opacity: .8;
        }
    } 
    .more-width {
        max-width: 720px;
    }
}
@media (max-width: 576px) {
    .interior {
        .contact-form {
            .form {
                .card-body {
                    padding: 20px;
                    border: none;
                }
            }
        }
    }
    .ctas {
        .more-pad {
            padding: 0 20px 0 20px !important;
        }
    }
    .main-slide {
        .new-h {
            height: 250px !important;
        }
        .panel1 {
            background-position: 75% 20% !important;
            background-size: cover;
        }
        .panel2 {
            background-position: 75% 20% !important;
            background-size: cover;
        }
        .panel3 {
            background-position: 75% 20% !important;
            background-size: cover;
        }
        .slide-tag {
            // display: none;
            font-size: 23px;
            line-height: 27px;
            right: 0;
            float: left;
        }
    }
    .more-width {
        max-width: 540px;
    }
}
@media (max-width: 480px) {
    .main-contact .btn {
        width: 55%;
    }
    .testimonials .panel-image {
        opacity: .7;
        background-position: -100px 0 !important;
    }
    .main-slide {
        .slide-tag {
            display: none;
        }
    }
}
@media (max-width: 320px) {
    .testimonials .panel-image {
        background-position: -230px 0 !important;
    }
}